import React, {Fragment, useState} from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {BgImage} from 'gbimage-bridge';
import {GiNeedleDrill} from 'react-icons/gi';
import {MdLocationOn} from 'react-icons/md';
import {Dialog, Transition} from '@headlessui/react';
import {MdOutlineClose} from 'react-icons/md';
import ApplyForm from '../components/apply';

import Layout from '../components/layout';
import Seo from '../components/seo';

const EmploymentPage = () => {
    const data = useStaticQuery(graphql `
    query {
        employmentImage: file(relativePath: {eq: "employment-page.jpg"}) {
          childImageSharp {
            gatsbyImageData
          }
        }
      }                  
  `);

    let [isOpen,
        setIsOpen] = useState(false)

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }

    const bgImg = data.employmentImage.childImageSharp.gatsbyImageData;

    return (
        <Layout>
            <Seo title="Careers"/>
            <section id="careers">
                <div className="w-full min-h-screen grid grid-cols-1 lg:grid-cols-2 bg-black">
                    <BgImage
                        image={bgImg}
                        style={{
                        minWidth: "100%",
                        minHeight: "100vh",
                        backgroundPosition: "top"
                    }}>
                        <div
                            className="flex px-5 lg:px-10 py-6 min-h-screen h-full justify-center items-center bg-black bg-opacity-40 pt-topMobile lg:pt-top">
                            <h1 className="text-white text-center uppercase halogen text-2xl">Job Opportunities</h1>
                        </div>
                    </BgImage>
                    <div
                        className="w-full h-full flex flex-col justify-center px-5 lg:px-10 bg-darkRed lg:pt-top">
                        <div className="py-5 lg:py-10">
                            <div className="w-full bg-black px-4 py-4 shadow-md mb-5 lg:mb-10">
                                <div className="flex flex-col justify-start">
                                    <div className="flex justify-between items-center">
                                        <div
                                            className="text-lg font-semibold text-bookmark-blue flex space-x-1 items-center mb-2">
                                            <GiNeedleDrill className="text-red text-2xl"/>
                                            <span className="text-white">Driller</span>
                                        </div>
                                    </div>
                                    <div className="text-sm text-red flex space-x-1 items-center mb-6">
                                        <MdLocationOn className="text-red text-xl"/>
                                        <span className="text-white">Canada</span>
                                    </div>
                                    <div className="text-white px-2">
                                        <p className="mb-3">Reporting to the Foreman, the Driller sets up and operates
                                            drilling equipment in a safe and efficient manner to maximize core recovery.
                                            Physical work involves assembling the drill components, servicing and
                                            maintaining hydraulic power packs, installing and maintaining waterlines and
                                            using all drill string components, and other related activities. Helm Drillers
                                            help to develop their Driller’s Helpers, offering regular guidance and training.
                                            On a day-to-day basis, they have a range of operational responsibilities from
                                            inspecting drill rods to communicating with helicopter pilots..</p>
                                        <p className="uppercase underline">Qualifications:</p>
                                        <ul className="list-disc list-outside pl-5">
                                            <li>Must have experience operating a diamond drill
                                            </li>
                                            <li>Must be able to travel and work in remote locations for extended periods of time
                                            </li>
                                            <li>Applicants must have a proactive approach to safety and the environment</li>
                                            <li>Mechanical experience an asset</li>
                                            <li>Valid Class 5 Drivers’ License an asset, Class 3 preferred</li>
                                            <li>Hold a valid Standard First Aid Certificate, with CPR-C-AED</li>
                                            <li>Hold a valid WHMIS certificate</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <div className="mt-5 ml-3">
                                            <button className="btn-xs" onClick={openModal}>Apply</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full bg-black px-4 py-4 shadow-md">
                                <div className="flex flex-col justify-start">
                                    <div className="flex justify-between items-center">
                                        <div
                                            className="text-lg font-semibold text-bookmark-blue flex space-x-1 items-center mb-2">
                                            <GiNeedleDrill className="text-red text-2xl"/>
                                            <span className="text-white">Driller Helper</span>
                                        </div>
                                    </div>
                                    <div className="text-sm text-red flex space-x-1 items-center mb-6">
                                        <MdLocationOn className="text-red text-xl"/>
                                        <span className="text-white">Canada</span>
                                    </div>
                                    <div className="text-white px-2">
                                        <p className="mb-3">The Driller’s Helper provides support to the Driller in
                                            obtaining core samples. This requires hard physical work such as continuously
                                            pulling rods and emptying tubes, assisting to set up the drill, connecting
                                            hydraulic hoses, preparing inner and outer tubes, and more. They are also
                                            responsible for maintaining a water source and keeping water pumps and lines in
                                            safe, working order.</p>
                                        <p className="uppercase underline">Qualifications:</p>
                                        <ul className="list-disc list-outside pl-5">
                                            <li>Must be in a physical condition suitable to work 12 hour days/nights of
                                                physical labour</li>
                                            <li>Must be able to travel and work in remote locations for extended periods of time</li>
                                            <li>Valid Class 5 Drivers’ License an asset, Class 3 preferred</li>
                                            <li>Mechanical experience an asset</li>
                                            <li>Applicants must have a proactive approach to safety and the environment</li>
                                            <li>Hold a valid Standard First Aid Certificate, with CPR-C-AED</li>
                                            <li>Hold a valid WHMIS certificate</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <div className="mt-5 ml-3">
                                            <button className="btn-xs" onClick={openModal}>Apply</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Transition appear show={isOpen} as={Fragment}>
                    <Dialog as="div" className="fixed inset-0 z-50" onClose={() => {}}>
                        <Dialog.Overlay className="fixed inset-0"/>
                        <div className="min-h-screen">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0">
                                <Dialog.Overlay className="fixed inset-0"/>
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span className="inline-block h-screen align-middle" aria-hidden="true">
                                &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95">
                                <div
                                    className="inline-block w-full h-screen overflow-scroll align-middle transition-all transform bg-darkRed shadow-xl">
                                    <div className="absolute m-2 top-0 right-0">
                                        <MdOutlineClose
                                            className="text-5xl text-white cursor-pointer"
                                            onClick={closeModal}/>
                                    </div>
                                    <ApplyForm/>
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition>
            </section>

            <form
                hidden
                name="application"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field">
                <p style={{
                    display: "none"
                }}>
                    <label>
                        Don’t fill this out if you expect to hear back!
                        <input name="bot-field" value="" readOnly/>
                    </label>
                </p>
                <input type="hidden" name="form-name" value="application"/>

                <select required={true} name="interested_position">
                    <option value="" disabled selected>Select an option</option>
                    <option value="driller">Driller</option>
                    <option value="driller_helper">Driller Helper</option>
                </select>

                <input required={true} placeholder="First Name" type="text" name="first_name"/>

                <input required={true} placeholder="Last Name" type="text" name="last_name"/>

                <input
                    required={true}
                    placeholder="Phone Number"
                    type="tel"
                    name="phone_number"/>

                <input required={true} placeholder="Email" type="text" name="email"/>

                <select required={true} name="years_of_experience">
                    <option value="" disabled selected>Years of Experience (Choose One)</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5+">5+</option>
                    <option value="10+">10+</option>
                    <option value="20+">20+</option>
                </select>

                <textarea required={true} name="applicant_experience"/>

                <input
                    required={true}
                    multiple
                    name="resume"
                    type="file"
                    id="upload"
                    accept=".pdf,.doc,.docx,.rtf,.txt,.odt,.tex"/>
                <input
                    required={false}
                    name="cover_letter"
                    type="file"
                    id="upload"
                    accept=".pdf,.doc,.docx,.rtf,.txt,.odt,.tex"/>
            </form>

        </Layout>
    )
};

export default EmploymentPage;
