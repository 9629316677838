import React, { useState } from "react"
import { AiOutlineHome, AiOutlineMail } from "react-icons/ai"
import { FiPhoneCall } from "react-icons/fi"

function encode(data) {
  const formData = new FormData()

  for (const key of Object.keys(data)) {
    formData.append(key, data[key])
  }

  return formData
}

const ApplyForm = () => {
  const [submitText, setSubmitText] = useState(null)

  const [state, setState] = useState({})

  const handleChange = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  const handleAttachment = e => {
    setState({
      ...state,
      [e.target.name]: e.target.files[0],
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => {
        setSubmitText(
          "Successfully submitted! We will get back to you as soon as possible."
        )
      })
      .catch(_ => {
        setSubmitText(
          "There was an error with your submission, please email us using this address: info@helmdiamonddrilling.ca"
        )
      })
  }

  return (
    <div
      id="application-form"
      className="justify-center px-5 lg:px-10 py-10 text-white"
    >
      <h1 className="my-4 halogen uppercase">Application Form</h1>
      <div className="flex pb-10">
        <div className="w-full">
          {submitText && (
            <div>
              <p className="halogen text-xl text-center text-white py-72">
                {submitText}
              </p>
              <div className="bg-black flex flex-col shadow-xl p-10">
                <p className="flex">
                  <AiOutlineHome className="mr-2 text-lg" />
                  316 Binche Keyoh Way
                </p>
                <p className="ml-6">
                  Binche B.C P.O. Box 2027
                  <br />
                  V0J 1P0
                </p>
                <p className="flex mt-5 items-center">
                  <AiOutlineMail className="mr-2 text-lg" />
                  <a href="mailto:info@helmdiamonddrilling.ca">
                    info@helmdiamonddrilling.ca
                  </a>
                </p>
                <p className="flex items-center">
                  <FiPhoneCall className="mr-2 text-lg" />
                  (250) 961-2409
                </p>
              </div>
            </div>
          )}
          {submitText ? null : (
            <form
              name="application"
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
            >
              <p
                style={{
                  display: "none",
                }}
              >
                <label>
                  Don’t fill this out if you expect to hear back!
                  <input
                    name="bot-field"
                    value=""
                    readOnly
                    onChange={handleChange}
                  />
                </label>
              </p>
              <input type="hidden" name="form-name" value="application" />

              <div id="interested-position">
                <h2 className="mb-5 uppercase heading-black">
                  Interested Position
                </h2>
                <select
                  required={true}
                  className="form-control bg-grey border-b-2 outline-none border-gray-600 w-full h-16 p-3 text-gray-700"
                  name="interested_position"
                >
                  <option value="" disabled selected>
                    Select an option
                  </option>
                  <option value="driller">Driller</option>
                  <option value="driller_helper">Driller Helper</option>
                </select>
              </div>

              <div id="personal-information">
                <h2 className="my-5 uppercase heading-black">
                  Personal Information
                </h2>
                <div className="flex flex-row space-x-5">
                  <div className="mb-4 w-1/2">
                    <input
                      required={true}
                      placeholder="First Name"
                      className="form-control bg-grey border-b-2 outline-none border-gray-600 w-full h-16 p-3 text-gray-700"
                      type="text"
                      name="first_name"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-4 w-1/2">
                    <input
                      required={true}
                      placeholder="Last Name"
                      className="form-control bg-grey border-b-2 outline-none border-gray-600 w-full h-16 p-3 text-gray-700"
                      type="text"
                      name="last_name"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="flex flex-row space-x-5">
                  <div className="w-1/2">
                    <input
                      required={true}
                      placeholder="Phone Number"
                      className="form-control bg-grey border-b-2 outline-none border-gray-600 w-full h-16 p-3 text-gray-700"
                      type="tel"
                      name="phone_number"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-1/2">
                    <input
                      required={true}
                      placeholder="Email"
                      className="form-control bg-grey border-b-2 outline-none border-gray-600 w-full h-16 p-3 text-gray-700"
                      type="text"
                      name="email"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              <div id="experience">
                <h2 className="my-5 uppercase heading-black">
                  Experience in the Industry
                </h2>
                <select
                  required={true}
                  className="form-control bg-grey border-b-2 outline-none border-gray-600 w-full h-16 p-3 text-gray-700 mb-4"
                  name="years_of_experience"
                  onChange={handleChange}
                >
                  <option value="" disabled selected>
                    Years of Experience (Choose One)
                  </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5+">5+</option>
                  <option value="10+">10+</option>
                  <option value="20+">20+</option>
                </select>

                <div className="mb-4">
                  <textarea
                    placeholder="Briefly describe your experience as it relates to the job you are applying for."
                    required={true}
                    className="form-control bg-grey border-b-2 outline-none border-gray-600 w-full h-44 p-3 text-gray-700"
                    name="applicant_experience"
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div id="resume" className="mb-6">
                <h2 className="my-5 uppercase heading-black">Your Resume</h2>
                <input
                  required={true}
                  name="resume"
                  type="file"
                  className="block cursor-pointer text-white focus:outline-none focus:border-transparent text-sm"
                  id="upload"
                  accept=".pdf,.doc,.docx,.rtf,.txt,.odt,.tex"
                  onChange={handleAttachment}
                />
                <h2 className="my-5 uppercase heading-black">
                  Your Cover Letter (Optional)
                </h2>
                <input
                  required={false}
                  name="cover_letter"
                  type="file"
                  className="block cursor-pointer text-white focus:outline-none focus:border-transparent text-sm"
                  id="upload"
                  accept=".pdf,.doc,.docx,.rtf,.txt,.odt,.tex"
                  onChange={handleAttachment}
                />
              </div>

              <button type="submit" aria-label="button" className="btn-sm mb-6">
                Apply
              </button>

              <div id="info" className="bg-black flex flex-col shadow-xl p-10">
                <p className="flex">
                  <AiOutlineHome className="mr-2 text-lg" />
                  3649 Opie Crescent
                </p>
                <p className="ml-6">
                  Prince George, BC
                  <br />
                  V2N 1B9
                </p>
                <p className="flex mt-5 items-center">
                  <AiOutlineMail className="mr-2 text-lg" />
                  <a href="mailto:info@helmdiamonddrilling.ca">
                    info@helmdiamonddrilling.ca
                  </a>
                </p>
                <p className="flex items-center">
                  <FiPhoneCall className="mr-2 text-lg" />
                  (250) 552-4224
                </p>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  )
}

export default ApplyForm
